import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const axios = require("axios");
const jQuery = require("jquery");

const RegistroPage = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [direction, setDirection] = useState("")
    const [subject] = useState("Usuario desea Registrarse en la Fundación Pedro Pérez Garrido");
    const [sitio] = useState("Fundación Pedro Pérez Garrido");

    const send = async function (e) {
        e.preventDefault()

        jQuery("#btn-cont").css('pointer-events', 'none')
        jQuery("#btn-cont").html('Enviando...')

        if (!executeRecaptcha) {
            return
        }

        if (email.length === 0 || email.trim().length === 0) {
            return
        } else if (message.length === 0 || message.trim().length === 0) {
            return
        } else if (subject.length === 0 || subject.trim().length === 0) {
            return
        } else if (name.length === 0 || name.trim().length === 0) {
            return
        } 
        const result = await executeRecaptcha("registro")
        const headers = {
            "Content-Type": "application/json"
        }
        axios
            .post(
                "https://us-central1-cloudsites-190318.cloudfunctions.net/mails-fmp",
                // "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
                {
                    name,
                    email,
                    message,
                    subject,
                    phone,
                    sitio,
                    token: result,
                    direction
                },
                {
                    headers: headers
                }
            )
            .then(res => {
                jQuery("#contact-form").slideUp("slow").hide();
                jQuery("#result").append(
                    '<hr class="mt-4"><div class="success text-center"><h4>¡Correo electrónico enviado con éxito!</h4><br><p>Gracias por usar nuestro formulario de Registro</p></div><hr>'
                )
            })
    }

    return (
        <Layout>
            <SEO title="Home"/>

            <div className="up bg-white spacer feature3 mt-5">
                <div className="container">
                    <div className="row m-0">

                        <div className="col-lg-5">

                            <div className="contact-box m-l-30">
                                <h1 className="title font-light m-t-10 text-right">Regístrate</h1>
                                <div id="result"></div>
                                <form onSubmit={event => send(event)} method="post" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="name" required className="form-control"
                                                       placeholder="Nombre"
                                                       onChange={event => setName(event.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="email" required className="form-control" type="email"
                                                       placeholder="Correo"
                                                       onChange={event => setEmail(event.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="phone" className="form-control" type="tel"
                                                       placeholder="Teléfono"
                                                       onChange={event => setPhone(event.target.value)}/></div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                            <textarea name="direction" required className="form-control"
                                                      placeholder="Dirección" defaultValue={""}
                                                      onChange={event => setDirection(event.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                            <textarea name="message" required className="form-control"
                                                      placeholder="Mensaje" defaultValue={""}
                                                      onChange={event => setMessage(event.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-right">
                                            <button type="submit" id="btn-cont"
                                                    className="btn btn-outline-info m-t-20 btn-arrow">
                                                <span> Enviar <i className="ti-arrow-right"></i></span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card-shadow">
                                {/* <img src={`../../images/contact/Contacto.jpg`} alt="wrapkit"
                                     className="img-responsive"/> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                         </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default RegistroPage
